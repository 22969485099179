
import './App.css';
import React, { lazy } from 'react';
import {
    Box,
    Flex,
    Heading,
    Link,
} from '@chakra-ui/react';
import {
    BrowserRouter as Router,
    Route,
    Routes,
} from 'react-router-dom';

import ComingSoon from './components/ComingSoon';

const CourseRecommendations = lazy(() =>
    import('./components/CourseRecommendations')
);


function App() {
    return (
        <Router>
            <Box
                bg="gray.900"
                minH="100vh"
                px={6}
                py={8}
                style={{
                    backgroundImage: 'url(./assets/background.jpg)',
                    backgroundSize: 'cover',
                    backgroundBlendMode: 'overlay',
                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                }}
            >
                <Flex
                    direction="column"
                    alignItems="center"
                    justifyContent="space-between"
                    maxW="4xl"
                    mx="auto"
                    w="full"
                    minH="100vh"
                >
                    <Flex justifyContent="space-between" alignItems="center" w="full" mb={12}>
                        <Heading as="h1" size="xl" fontWeight="bold">
                            <Link href="/" color="teal.500">
                                studygenie.ai
                            </Link>
                        </Heading>
                    </Flex>
   
                    <Routes>
                        <Route path="/" element={<ComingSoon />} />
                        <Route path="/recommendations" element={<CourseRecommendations />} />
                        <Route path="/coming-soon" element={<ComingSoon />} />
                    </Routes>

                    <Flex justifyContent="space-between" alignItems="center" w="full" mt={20}>
                        <Box color="whiteAlpha.700">
                            Powered by{' '}
                            <Box as="span" fontWeight="bold">
                                OpenAI
                            </Box>{' '}
                        </Box>

                    </Flex>
                </Flex>
            </Box>
        </Router>
    );
}

export default App;
