// src/theme.js
import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  fonts: {
    heading: 'Poppins, sans-serif',
    body: 'Poppins, sans-serif',
  },
  colors: {
    brand: {
      100: '#E5FCF1',
      200: '#27EF96',
      300: '#10DE82',
      400: '#0EBE6F',
      500: '#0CA25F',
      600: '#0A864F',
      700: '#086F42',
      800: '#075C37',
      900: '#064C2E',
    },
  },
  components: {
    Button: {
      baseStyle: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
      },
      variants: {
        solid: (props) => ({
          bg: props.colorMode === 'dark' ? 'brand.300' : 'brand.500',
          color: 'white',
          _hover: {
            bg: props.colorMode === 'dark' ? 'brand.200' : 'brand.600',
          },
        }),
      },
    },
  },
});

export default theme;
