import React, { useState } from 'react';
import {
  Box,
  Heading,
  Text,
  Input,
  Button,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import axios from 'axios';

const ComingSoon = () => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setMessage('');

    try {
      const response = await axios.post('/api/send-email', { email });
      setIsLoading(false);
      setMessage(response.data.message);
      setEmail('');
    } catch (error) {
      setIsLoading(false);
      setMessage('An error occurred while submitting the form. Please try again.');
    }
  };

  return (
    <Box
      bg="gray.900"
      minH="50vh"
      px={6}
      py={8}
      color="white"
      style={{
        backgroundImage: 'url(./assets/background.png)',
        backgroundSize: 'cover',
        backgroundBlendMode: 'overlay',
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
      }}
    >
      <Box maxW="xl" mx="auto" textAlign="center">
        <Heading as="h1" size="2xl" mb={8}>
          StudyGenie is coming soon!
        </Heading>
        <Text fontSize="xl" mb={12}>
          We're working hard to bring you the best AI powered content. Sign up below to be notified when we launch!
        </Text>
        <form onSubmit={handleSubmit}>
          <FormControl id="email" mb={4}>
            <FormLabel>Enter your email:</FormLabel>
            <Input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </FormControl>
          <Button
            type="submit"
            colorScheme="teal"
            isLoading={isLoading}
            loadingText="Submitting"
            spinnerPlacement="end"
            mt={4}
          >
            Notify me!
          </Button>
        </form>
        {message && <Text mt={4}>{message}</Text>}
      </Box>
    </Box>
  );
};

export default ComingSoon;
